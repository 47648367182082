import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Formik } from 'formik'
import { Form, FormItem, Input, SubmitButton } from 'formik-antd'
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js'
import React, { FC, useCallback } from 'react'
import * as Yup from 'yup'

const ContactInfoSchema = Yup.object().shape({
  customer_name: Yup.string()
    .required('Your name is required')
    .min(5, 'Invalid input, Minimum 5 characters!!!')
    .max(25, 'Invalid Input. Maximum 25 characters!!!')
    .trim(),
  phone_number: Yup.string()
    .required('Phone number is required')
    .matches(/^[1-9]\d{9}$/, {
      message: 'Invalid Phone Number Format',
    })
    .test('is-us-phone-number', 'Invalid Phone Number', (value) => {
      if (value) {
        const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(value, 'US')
        if (phoneNumber) {
          return !!phoneNumber.isValid()
        }
      }
      return false
    }),
  email: Yup.string().email('Invalid Email Address').trim(),
  message: Yup.string()
    .required('Message is required')
    .min(20, 'Minimum 20 characters')
    .max(100, 'Maximum 200 characters'),
})

const defaultValues = {
  customer_name: '',
  phone_number: '',
  email: '',
  message: '',
}

const ContactUs: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleFormSubmit = useCallback(({ values, actions }) => {}, [])

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={ContactInfoSchema}
      onSubmit={handleFormSubmit}
    >
      {() => (
        <Form layout="vertical">
          <FormItem name="customer_name" required label="Your Name">
            <Input
              name="customer_name"
              placeholder="First and Last Name"
              allowClear
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="phone_number" required label="Your Phone Number">
            <Input
              name="phone_number"
              placeholder="##########"
              allowClear
              prefix={<PhoneOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="email" label="Your Email">
            <Input
              type="text"
              name="email"
              placeholder="example@domain.com"
              allowClear
              prefix={<MailOutlined className="site-form-item-icon" />}
            />
          </FormItem>

          <FormItem name="message" required label="Your Message">
            <Input.TextArea
              name="message"
              placeholder="We love hearing from you"
              autoSize={{ minRows: 3, maxRows: 8 }}
            />
          </FormItem>

          <SubmitButton shape="round">Looks Good, Send</SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUs
