import { FC } from 'react'
import { Typography, Row, Col, Space, Button } from 'antd'
import { PhoneOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { palette } from '@root/ic-ui-react/theme'
import StyledContainer from '@root/ic-ui-react/StyledContainer'
import ContactForm from '@components/ContactForm'
import Layout from '@components/Layout'
import SEO from '@components/SEO'

const StyledStrong = styled.strong`
  color: ${palette.primaryColor};
`
const { Title, Paragraph } = Typography

const ContactUs: FC = () => (
  <Layout>
    <SEO title="Contact Us" />

    <StyledContainer fluid>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13258.928075004222!2d-118.1680739722428!3d33.81922804045051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd33c832baca77%3A0x94255379589ed3ad!2s3401%20Cherry%20Ave%2C%20Long%20Beach%2C%20CA%2090807!5e0!3m2!1sen!2sus!4v1610093527568!5m2!1sen!2sus"
        width="100%"
        height="280"
        frameBorder="0"
        style={{ border: '0' }}
        allowFullScreen
        aria-hidden="false"
        title="Pita Pitaki Address"
      />
    </StyledContainer>

    <StyledContainer padding="0.5rem" margin="1rem auto">
      <Title level={1} className="px-2">
        CONTACT US
      </Title>

      <Row gutter={[24, 12]} justify="space-between">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ContactForm />
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="px-2">
          <Title level={4}>OPEN HOURS</Title>
          <Paragraph>
            MON: <StyledStrong>CLOSED</StyledStrong> <br />
            TUES - SAT: <StyledStrong>10:30AM - 9:00PM</StyledStrong> <br />
            SUN: <StyledStrong>10:30AM - 8:00PM</StyledStrong>
          </Paragraph>

          <Title level={4}>CONTACT INFO</Title>

          <Space>
            <Button type="primary" shape="round" icon={<PhoneOutlined />} size="middle">
              (562) 424-0446
            </Button>
          </Space>
        </Col>
      </Row>
    </StyledContainer>
  </Layout>
)

export default ContactUs
